import React from 'react'

import './Formulas.css'
import Carousel from 'react-bootstrap/Carousel'
import CTA from '../CTA/CTA'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'


const  preGame  ='https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/blue%20rasberrysticker1.png?alt=media&token=ba790f87-6c58-4b1e-81db-5acfcf1ef696'
const  postGame = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/mangosticker2.png?alt=media&token=48ec170f-1542-4d0b-9be9-949899dfef89'
const  planB  ='https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pineapplesticker3.png?alt=media&token=994f3ef6-2b28-4063-96df-5751092accc4'




const items = [
  {
    name: 'Pre-game',
    image: <StaticImage loading='eager' src = {preGame} />,
    caption:
      'Remember what your 9th-grade health teacher used to say? Always use protection. Our pre-game formula is your first line of defense against the pain and suffering you experience after a late night.',
  },
  {
    name: 'Post-game',
    image: <StaticImage src={postGame}/>,
    caption:
      'Once the damage has been done, it becomes essential to give your body the fuel it needs to have a restful night and minimize the damages.',
  },
  {
    name: 'Plan-B',
    image: <StaticImage src={planB}/>,
    caption:
      `No, not that type of Plan-B! Our "morning-after" drink will make you feel ready to attack the day, whether that means catching up on work or slamming some Natty's.`,
  },
]



export default function Formulas({buttonVariant, handleCTA}) {
  return (
    <motion.div className="formulas" style = {{backgroundImage:'linear-gradient(to top, rgb(255, 241, 235) 0%, rgb(172, 224, 249) 100%)'}} >
      <div className="formulas-header">3 Electrolyte Formulas</div>
      <div className="formulas-subheader">Designed to prevent hangovers</div>
      <Carousel variant="dark" interval={10000}>
        {items.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="formula-content">
                <div className="formula-image-div">
                  
                  {item.image}
                  
                </div>
                <div className="formula-caption">{item.caption}</div>
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>
      <div style = {{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
        <CTA handleCTA={handleCTA} variant={buttonVariant} />
      </div>
      
    </motion.div>
  )
}
