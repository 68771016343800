import React from 'react'
//components
import './Flavors.css'
import CTA from '../CTA/CTA'
import Grid from '@mui/material/Grid'
import { StaticImage } from 'gatsby-plugin-image'
//images
const Bimg = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/raspberry-main.webp?alt=media&token=830b1e96-8320-4b84-9186-3b56bfadb657'
const Mimg = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/mango-main.webp?alt=media&token=8b5a47ad-992f-4f1f-bda7-2829fc6b10ad'
const Pimg = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pineapple-main.webp?alt=media&token=5e64d45c-35bf-4ffd-af23-87b4b701b6f9'



export default function Flavors({handleCTA, buttonVariant}) {
  return (
    <div className="flavors-main">
      <div className="flavors-center-text">
        <div className="fct-1">Available In 3</div>
        <div className="fct-1">Mouthwatering Flavors</div>
       <CTA handleCTA = {handleCTA} variant = {buttonVariant}/>
          
     
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={4} className="img-grid">
          <StaticImage  className="flavors-img" src={Bimg} alt = 'hangover prevention electrolytes, Bender blue raspberry flavor' />
        </Grid>

        <Grid item xs={12} lg={4} className="img-grid">
          <StaticImage  className="flavors-img" src={Mimg} alt = 'hangover prevention electrolytes, Bender blue raspberry flavor'/>
        </Grid>
     
        <Grid item xs={12} lg={4} className="img-grid">
          <StaticImage  className="flavors-img" src={Pimg} alt = 'hangover prevention electrolytes, Bender blue raspberry flavor'/>
        </Grid>
      
      </Grid>

      <div className="img-row"></div>
    </div>
  )
}
