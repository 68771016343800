import React from 'react'
import './ComparisonGrid.css'
import { StaticImage } from 'gatsby-plugin-image'



//images
const checked = '../../images/checked.png'
const exed = '../../images/xd.png'
const bgImg = '../../images/bg/bp.jpeg'

export default function ComparisonGrid() {
  return (
    <div className="CG-main" >
      <div className="CG-header">Why is Bender better?</div>
      <div className="table-headers">
        <div className="table-head-spacer" />
        <div className="table-head">550mg+ Electrolytes per serving</div>
        <div className="table-head">Improves Sleep Quality</div>
        <div className="table-head">Prevents nausea</div>
        <div className="table-head">Breaks down alcohol's toxins</div>
      </div>
      <div className="table-container">
        <div className="table-body">
          <div className="table-row highlight">
            <div className="table-cell-main">Bender</div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
          </div>
          <div className="table-row">
            <div className="table-cell-main">Liquid IV</div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
          </div>
          <div className="table-row">
            <div className="table-cell-main">Pedialyte</div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={checked} alt = 'check icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
          </div>
          <div className="table-row">
            <div className="table-cell-main">Gatorade</div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon' />
            </div>
            <div className="table-cell">
              <StaticImage className="table-icon" src={exed} alt = 'x icon'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
